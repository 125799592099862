import { post } from "../index";
const baseUrl = "/vocs/web/";
export function userInfo(path: string, params?: object) {
  interface paramType {
    [propName: string]: string;
  }
  const param:paramType = {
    // 首页用户信息
    indexUserInfo: "index/user-info",
   
  };
  const url= baseUrl + param[path];
  return post (url, params);
}
