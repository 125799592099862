export default  function(app: any) {
app.directive("resizes", {
  mounted(el: any, binding: any) {

    // el为绑定的元素，binding为绑定给指令的对象
    let width = '',
      height = '';

    function isResize() {
      const style = document.defaultView?.getComputedStyle(el);
      if (width !== style?.width || height !== style.height) {
        binding.value(); // 关键
      }
      width = style?.width || '';
      height = style?.height || '';
    }
    el.__vueSetInterval__ = setInterval(isResize, 300);
  },
  unmounted(el: { __vueSetInterval__: number | undefined; }) {
        clearInterval(el.__vueSetInterval__);
  }
})
}
