/**
 * 用户类型
 */
interface USER_TYPE {
  [propName: number]: string;
}

interface paramType {
  [propName: string | number]: string | number;
}
export const USER_TYPE_PLATFORM_USER = 1;
export const USER_TYPE_STATION_USER = 2;
export const USER_TYPE: USER_TYPE = {
  [USER_TYPE_PLATFORM_USER]: '平台用户',
  [USER_TYPE_STATION_USER]: '站点用户',
};

/**
 * 是否是超管
 */
export const IS_SUPER = 1


/**
 * 平台权限
 */

export const PLATFORM_ROLE_SYSTEM_ADMIN = 1;
export const PLATFORM_ROLE_OPERATION_ADMIN = 2;
export const PLATFORM_ROLE: paramType = {
  [PLATFORM_ROLE_SYSTEM_ADMIN]: '系统管理员',
  [PLATFORM_ROLE_OPERATION_ADMIN]: '运维管理员',
};

/**
 * 用户状态
 */
export const USER_STATUS_NORMAL = 1
export const USER_STATUS_FORBID = 2
export const USER_STATUS: paramType = {
  [USER_STATUS_NORMAL]: '正常',
  [USER_STATUS_FORBID]: '禁用',
};

// 用户类型



/**
 * 站点权限
 */
export const STATION_ROLE_ADMIN = 1
export const STATION_ROLE_MEMBER = 2
export const STATION_ROLE: paramType = {
  [STATION_ROLE_ADMIN]: '管理员',
  [STATION_ROLE_MEMBER]: '成员',
};

