import { post } from "../index";
const baseUrl = "/vocs/web/";
export function userLogin(path: string, params?: object) {
  interface paramType {
    [propName: string]: string;
  }
  const param:paramType = {
    // 密码登录
    loginPassword: "login/password",
    // 验证码登录
    loginSms: "login/sms",
    // 忘记密码
    passwordForgot: "password/forgot",
    // 忘记密码
    logout: "index/logout",
  };

  
  const url= baseUrl + param[path];
  return post (url, params);
}


export function sms(path: string, params?: object) {
  interface paramType {
    [propName: string]: string;
  }
  const param:paramType = {
    // 登录验证码
    smsLogin: 'sms/login',
    // 忘记密码验证码
    smsForgot: 'sms/forgot-password',
    // 修改密码验证码（需要登录）
    // updatepwd: 'sms/update/password'
  };

  

  
  const url= baseUrl + param[path];
  return post (url, params);
}