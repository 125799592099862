import router from '@/router'
import ElementPlus from 'element-plus'
import { createApp } from 'vue'

import registerDirectives from './directives' //自定义指令
//引入仓库
import pinia from './store'

// 这两个样式文件顺序不能颠倒
// import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

// import '../src/assets/fonts/iconfont.css'; // 引入自定义图标样式文件
import MyIcon from '@/components/commons/MyIcon/index.vue' // 你自己的vue文件路径

import StartEndDate from '@/components/commons/StartEndDate/index.vue'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import App from './App.vue'
import VueDragResizeRotate from '@gausszhou/vue3-drag-resize-rotate'
import '@gausszhou/vue3-drag-resize-rotate/lib/bundle.esm.css'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(VueDragResizeRotate)
app.component('MyIcon', MyIcon) // 使用
app.component('StartEndDate', StartEndDate)
app.use(router)
app.use(pinia)
app.use(ElementPlus, {
    locale: zhCn,
})

registerDirectives(app)
// 定义全局变量
app.config.globalProperties.$Num = 1 // 定义全局变量 数值类型
app.config.globalProperties.$Switch = 2 // 定义全局变量 开关类型
app.config.globalProperties.$Warn = 3 // 定义全局变量 报警类型
app.config.globalProperties.$Error = 4 // 定义全局变量 故障类型
app.config.globalProperties.$Yes = 1 // 定义全局变量 是
app.config.globalProperties.$No = 0 // 定义全局变量 否
app.mount('#app')
