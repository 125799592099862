import { AccountCenter } from "@/api/AccountCenter";
import { defineStore } from 'pinia';
interface UserInfo {
  type: number,
  platform_role: number,
  station_role: [{ role: number }],
  authorize_systems:number[]
}
export const useUserinfoStore = defineStore('userInfo', {
  state() {
    return {
      userInfo: { type: 0, platform_role: 0, station_role: [{ role: 0 }], authorize_systems: [] } as UserInfo,
      tokenExpiredNotified: false, // 标记是否已经提示过 token 失效
    }
  },
  actions: {
    async getUserinfo() {
      await AccountCenter("accountDetail").then(res => {
        this.userInfo = res.data || {};
      })
    },
    setTokenExpiredNotified(value: any) {
      this.tokenExpiredNotified = value
    },
  }
})
