import { post } from "../index";
const baseUrl = "/vocs/web/";
export function AccountCenter(path: string, params?: object) {
  interface paramType {
    [propName: string]: string;
  }
  const param:paramType = {
    // 添加用户
    userAdd: "user/add",
    // 用户列表
    userList: "user/list",
    // 用户详情
    userDetail: "user/detail",
    // 用户更新
    userUpdate: "user/update",
    // 账户详情
    accountDetail: "account/detail",
    // 编辑账户
    accountUpdate: "account/update",
    // 编辑邮箱
    accountUpdateEmail: "account/update-email",
    // 编辑密码
    accountUpdatePassword: "account/update-password",
    // 编辑手机
    accountUpdatePhone: "account/update-phone",
    // 发送验证码
    accountUpdatePhoneSms: "account/update-phone-sms",

   
  };
  const url= baseUrl + param[path];
  return post (url, params);
}
