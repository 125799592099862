import { ElMessage } from 'element-plus'

// 下载文件方法  filename文件命名, str需要下载的内容
export function saveAsPbtxt(filename: any, str: any, type: string, callback?: any) {
    const fileReader = new FileReader()
    fileReader.readAsText(str)
    fileReader.onload = function (result) {
        try {
            const jsondata = result?.target?.result && typeof result.target.result === 'string' ? JSON.parse(result.target.result) : ''
            if (jsondata.code !== 2000) {
                ElMessage.error(jsondata.msg || '请求失败')
                if (callback) {
                    callback(false)
                }
            }
        } catch (error) {
            const url = window.URL.createObjectURL(new Blob([str], { type: type }))
            const aDOM = document.createElement('a')
            aDOM.style.display = 'none'
            aDOM.href = url
            aDOM.setAttribute('download', filename)
            document.body.appendChild(aDOM)
            aDOM.click()
            document.body.removeChild(aDOM)
            if (callback) {
                callback(true)
            }
        }
    }
}


// 跳转至企业端
export function goEnterprise(token:string) {
    const url = window.location.protocol === 'https:' ? `https://owner.voc3d.com?pid=${token}` : `http://owner-test.voc3d.com?pid=${token}`
    window.location.href = url
}

