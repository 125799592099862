import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: 'login',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView/index.vue'),
    },
    {
        path: '/accountCenter',
        name: 'accountCenter',
        component: () => import('../views/AccountCenter/index.vue'),
    },
    {
        path: '/monitoringCenter',
        name: 'monitoringCenter',
        component: () => import('../views/MonitoringCenter/index.vue'),
        children: [
            {
                path: '',
                name: 'monitoringCenter',
                redirect: '/monitoringCenter/center',
            },
            {
                path: 'center',
                name: 'center',
                component: () => import('../components/MonitoringCenter/index.vue'),
            },
            {
                path: 'details',
                name: 'monitoringDetails',
                component: () => import('../components/MonitoringCenter/Information/Details.vue'),
            },
        ],
    },
    {
        path: '/operationCenter',
        name: 'operationCenter',
        component: () => import('../views/OperationCenter/index.vue'),
        children: [
            {
                path: '',
                name: 'operationCenter',
                redirect: '/operationCenter/taskList',
            },
            {
                path: 'stationManagement',
                name: 'stationManagement',
                component: () => import('../components/OperationCenter/StationManagement/StationManagement.vue'),
            },
            {
                path: 'vehicleList',
                name: 'vehicleList',
                component: () => import('../components/OperationCenter/VehicleManagement/VehicleList.vue'),
            },
            {
                path: 'vehicleDetail',
                name: 'vehicleDetail',
                component: () => import('../components/OperationCenter/VehicleManagement/VehicleDetail.vue'),
            },
            {
                path: 'taskList',
                name: 'tasList',
                component: () => import('../components/OperationCenter/TaskManagement/TaskList.vue'),
            },
            {
                path: 'taskDetail',
                name: 'taskDetail',
                component: () => import('../components/OperationCenter/TaskManagement/TaskDetail.vue'),
            },
            {
                path: 'dataStatistic',
                name: 'dataStatistic',
                component: () => import('../components/OperationCenter/DataStatistics/index.vue'),
            },
        ],
    },
    {
        path: '/customerCenter',
        name: 'customerCenter',
        component: () => import('../views/CustomerCenter/index.vue'),
        children: [
            {
                path: '',
                name: 'customerCenter',
                redirect: '/customerCenter/customerList',
            },
            {
                path: 'customerList',
                name: 'customerList',
                component: () => import('../components/CustomerCenter/CustomerList.vue'),
            },
            {
                path: 'customerDetail',
                name: 'customerDetail',
                component: () => import('../components/CustomerCenter/CustomerDetail/CustomerDetail.vue'),
            },
            {
                path: 'equipmentDetail',
                name: 'equipmentDetail',
                component: () => import('../components/CustomerCenter/EquipmentDetail/EquipmentDetail.vue'),
            },
            {
                path: 'dataStatistics',
                name: 'dataStatistics',
                component: () => import('../components/CustomerCenter/DataStatistics/DataStatistics.vue'),
            },
        ],
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../views/PlaneManagement/index.vue'),
        children: [
            {
                path: '',
                name: 'admin',
                redirect: '/admin/planeList',
            },
            {
                path: 'planeList',
                name: 'planeList',
                component: () => import('../components/PlaneManagement/PlaneList.vue'),
            },
            {
                path: 'drawing',
                name: 'drawing',
                component: () => import('../components/PlaneManagement/DeviceDrawingMap.vue'),
            },
            {
                path: 'addPlane',
                name: 'addPlane',
                component: () => import('../components/PlaneManagement/AddPlane.vue'),
            },
            {
                path: 'planeDetails',
                name: 'planeDetails',
                component: () => import('../components/PlaneManagement/components/PlaneDetails.vue'),
            },
        ],
    },
    {
        path: '/device',
        name: 'device',
        component: () => import('../views/Device/index.vue'),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router
