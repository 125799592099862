export default function (app: any) {
  app.directive("integer-only", {
    mounted(el: any, binding: any) {
      // el为绑定的元素，binding为绑定给指令的对象
      el.addEventListener('input', (event: { target: { value: any; }; }) => {
        const value = event.target.value;
        if (binding.value.type === 'int') {
          event.target.value =
            value.replace(/\D/g, '')// 只保留数字
              .replace(/^\0/g, '') // 保证第一个为数字而不是0; 
        } else {

          const str = value.
            replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
            .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
            .replace(/^\./g, '') // 保证第一个为数字而不是.
          event.target.value = str
          if (binding.value.reserveNum) {
            event.target.value = str.slice(0, binding.value.reserveNum + str.indexOf('.') + 1)
          }
        }
        if (binding.value.maxNum && Number(event.target.value)) {
          if (Number(event.target.value) > binding.value.maxNum) {
            event.target.value = binding.value.maxNum + ''
          }
        }
      });
    },

  })
}
